import ComponentBase from './ComponentBase';
import clsx from 'clsx';
// import DatePicker from 'react-datepicker';

// icon
import Icon from './Icon';

// helpers
import { find, inputByName } from 'classes/Dom';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarArrowDown } from '@fortawesome/pro-light-svg-icons';

class Field extends ComponentBase {
  constructor(props) {
    super(props);

    this.classes = [
      'outline-0',
      'shadow-none',
      'focus:ring-0',
      'border-0',
      'bg-transparent',
      'transition',
      'duration-300',
      'w-full',
      'pl-16',
      'px-5',
      'placeholder-gray-600',
      'dark:placeholder-gray-400',
      'dark:text-gray-100',
      'h-full',
    ];

    const fileTypes = [
      { name: 'From File', type: 'file', inputName: props.name },
      { name: 'From URL', type: 'url', inputName: props.name2 ?? props.name },
    ];

    this.state = {
      date: '',
      startDate: null,
      fileType: fileTypes[0],
      fileTypes,
      dropdown: false,
      showPicker: false,
    };

    if (!props.icon) this.classes = this.removeClass('pl-16', this.classes);
  }

  togglePassword(name, e) {
    const toggler = e.currentTarget;
    const passwordInput = inputByName(name, toggler.parentNode);

    if (passwordInput.getAttribute('type') == 'password') {
      passwordInput.setAttribute('type', 'text');

      find(toggler, '.toggler--show').classList.add('hidden');
      find(toggler, '.toggler--hide').classList.remove('hidden');
    } else {
      passwordInput.setAttribute('type', 'password');

      find(toggler, '.toggler--show').classList.remove('hidden');
      find(toggler, '.toggler--hide').classList.add('hidden');
    }
  }

  changeDate(date) {
    this.setState({
      date: date,
    });
  }

  render() {
    const { placeholder, ...props } = this.props;
    const state = this.state;
    const classesResolve = this.classesResolve();
    const dateObject = isNaN(new Date(state.date))
      ? new Date()
      : new Date(state.date);

    let options = props.options;

    return (
      <div className="border border-gray-200 dark:border-gray-700 dark:focus-within:border-primary-500 focus-within:border-primary-500 dark:text-gray-400 dark:focus-within:text-primary-500 py-2 rounded">
        {(props.label || placeholder) && (
          <label className="text-gray-600 dark:text-gray-400 p-4 text-sm">
            {props.label || placeholder}
            {props.required && (
              <span className="text-red-500 font-semibold ml-1">*</span>
            )}
          </label>
        )}
        <div
          className={`flex items-center relative ${props.wrapperClassName} ${
            props.append
              ? classesResolve.replace(/pl-16/, '').replace(/px-5/, '')
              : ''
          }`.trim()}>
          {props.icon && (
            <div className="absolute pr-3 ml-4 text-current border-r dark:border-gray-700">
              {props.icon}
            </div>
          )}
          {props.type == 'select' ? (
            <div className="w-full">
              {/*<Select 
								styles={customStyles} 
								options={options} 
								name={props.name}
								value={props.value}
								onChange={props.onChange}
								placeholder={props.placeholder} />*/}

              <select
                disabled={props.disabled}
                className={classesResolve + ' form-select'}
                name={props.name}
                defaultValue={props.defaultValue}
                onChange={props.onChange}>
                {options.map((item) => {
                  if ('options' in item) {
                    return (
                      <optgroup key={item.label} label={item.label}>
                        {item.options.map((child) => (
                          <option
                            key={child.value ?? child.label}
                            disabled={child.disabled ?? false}
                            value={child.value ?? child.label}>
                            {child.label}
                          </option>
                        ))}
                      </optgroup>
                    );
                  } else {
                    return (
                      <option
                        key={item.value}
                        disabled={item.disabled ?? false}
                        value={item.value}>
                        {item.label}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          ) : //else if

          props.type == 'date' ? (
            // 	<div className={classesResolve}>
            // 		<DatePicker
            // 			placeholderText={props.placeholder}
            // 			name={props.name}
            // 			selected={this.state.startDate}
            // 			onChange={this.changeDate.bind(this)} />
            // 	</div>
            <div className="relative w-full flex items-center">
              <input
                type="text"
                className={clsx(classesResolve, 'w-full')}
                value={state.date}
                placeholder="e.g 1990-01-10"
                onChange={(e) => {
                  this.setState({ date: e.target.value });
                }}
                name={props.name}
              />
              <button
                className="ml-auto mr-4"
                type="button"
                onClick={() =>
                  this.setState({ showPicker: !state.showPicker })
                }>
                <FontAwesomeIcon icon={faCalendarArrowDown} size="lg" />
              </button>
              {state.showPicker && (
                <div
                  className={clsx(
                    'absolute z-10 shadow-lg rounded top-12 right-0',
                    'text-black bg-white dark:bg-gray-800 dark:text-white/80'
                  )}>
                  <div className="flex p-4 border-b border-gray-200 dark:border-gray-700">
                    <h2 className="font-semibold text-sm">Pick a Date</h2>
                    <div className="ml-auto">
                      <buton
                        type="button"
                        className="cursor-pointer"
                        onClick={() => this.setState({ showPicker: false })}>
                        <FontAwesomeIcon icon={faClose} size="lg" />
                      </buton>
                    </div>
                  </div>
                  <DayPicker
                    className="hover:[&_button]:text-black"
                    mode="single"
                    selected={dateObject}
                    defaultMonth={dateObject}
                    onSelect={(date) => {
                      this.setState({
                        date: format(date, 'yyyy-MM-dd'),
                        showPicker: false,
                      });
                    }}
                    captionLayout="dropdown-buttons"
                    fromYear={1945}
                    toYear={new Date().getFullYear()}
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              {props.append && (
                <div className="ml-16 text-gray-500">{placeholder}</div>
              )}
              {props.type == 'file' && props.urlOptions && (
                <div className="absolute top-0 right-4 h-full flex items-center">
                  <button
                    className="text-gray-400 flex items-center hover:text-white transition"
                    type="button"
                    onClick={() => this.setState({ dropdown: true })}>
                    {state.fileType.name}
                    <Icon name="chevron-down" className="w-4 ml-1" />
                  </button>
                  <div
                    className={`absolute bg-white shadow-lg dark:bg-gray-800 dark:border dark:border-gray-700 rounded top-full right-0 w-40 ${
                      state.dropdown === true ? '' : 'hidden'
                    }`}>
                    {state.fileTypes.map((f) => (
                      <button
                        key={f.name}
                        onClick={() => {
                          this.setState({ dropdown: false, fileType: f });
                          props.urlOptions.call(this, f.type);
                        }}
                        type="button"
                        className={`w-full text-left py-2 px-4 dark:text-gray-100 dark:hover:bg-gray-700 hover:bg-gray-100 cursor-pointer ${
                          f.type == state.fileType.type
                            ? 'dark:bg-gray-700 bg-gray-100'
                            : ''
                        }`}>
                        {f.name}
                      </button>
                    ))}
                  </div>
                </div>
              )}
              <input
                id={props.id ?? props.name}
                type={
                  props.type == 'file' && props.urlOptions
                    ? state.fileType.type
                    : props.type
                }
                placeholder={placeholder}
                className={clsx(
                  props.urlOptions
                    ? classesResolve.replace(/px-5/, 'pr-28')
                    : classesResolve,
                  props.type === 'file' &&
                    'py-2 file:bg-gray-200 file:border-none file:text-xs file:uppercase file:tracking-wide file:font-semibold file:py-1 file:px-3 file:rounded-full dark:file:bg-gray-800 dark:file:text-white file:float-right'
                )}
                name={
                  props.type == 'file' && props.urlOptions
                    ? state.fileType.inputName
                    : props.name
                }
                value={props.value}
                defaultValue={props.defaultValue}
                ref={props.asref}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                tabIndex={props.tabIndex}
                onChange={
                  state.fileType.type === 'file' ? props.onChange : () => {}
                }
              />

              {props.eye && props.type == 'password' && (
                <div
                  className="absolute right-0 mr-4 text-gray-600 cursor-pointer hover:text-primary-500"
                  onClick={this.togglePassword.bind(this, props.name)}>
                  <Icon className="toggler--show" name="eye" width="18" />
                  <Icon
                    className="hidden toggler--hide"
                    name="eye-off"
                    width="18"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export const optionsFromArrayStr = (array) => {
  const options = [];

  array.map((ar) => {
    if (typeof ar == 'string') {
      return options.push({
        label: ar,
        value: ar,
      });
    }

    options.push(ar);
  });

  return options;
};

export default Field;
